"use client";
import "~/styles/globals.css";
import React from "react";
import { Manrope } from "next/font/google";
import { RecoilRoot } from "recoil";
import AuthProvider from "~/providers/AuthProvider";
import TanstackProvider from "~/providers/TanstackProvider";
import { Toaster } from "sonner";

const manrope = Manrope({
  subsets: ["latin"],
});

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <html lang="en">
      <body
        className={`${manrope.className} bg-brand-gray-100 dark:bg-gray-900 `}
      >
        <RecoilRoot>
          <AuthProvider>
            <TanstackProvider>{children}</TanstackProvider>
          </AuthProvider>
          <Toaster richColors />
        </RecoilRoot>
      </body>
    </html>
  );
}
